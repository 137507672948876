import { splitProps } from "solid-js";
import { createSubmissionFilters } from "../../../forms/submission-filter";
type SubmissionFilterProps = {
  submissions_count: number;
  englishLevel: string;
  regions: {
    id: number | string;
    name: string;
    is_deleted: boolean;
    country: number | null;
  }[];
  comissions: {
    id: number;
    name: string;
  }[];
  onFilterSelected: (data) => void;
};
export function SubmissionFilter(props: SubmissionFilterProps) {
  const dropdownStyle = "cursor-pointer mr-25px";
  const [local, other] = splitProps(props, ["submissions_count", "englishLevel", "regions", "comissions"]);
  const SubmissionFilters = createSubmissionFilters();
  const onFilterChange = () => {
    const filters = SubmissionFilters.Actions.getValuesSnakecase();
    other.onFilterSelected(filters);
  };
  const onReset = () => {
    SubmissionFilters.EnglishLevel.control.setValue(undefined);
    SubmissionFilters.Region.control.setValue(undefined);
    SubmissionFilters.WhichCommission.control.setValue(undefined);
    onFilterChange();
  };
  return (
    <form class="flex flex-col gap-40px" onchange={onFilterChange}>
      <div class="flex justify-between">
        <p>{local.submissions_count} submissions</p>
        <button type="reset" class="underline cursor-pointer" onClick={onReset}>
          Clear Filters
        </button>
      </div>
      <div class="flex gap-50px  flex-wrap">
        <div class="flex items-center gap-10px">
          <SubmissionFilters.IsSaudi.Input type="checkbox" class="w-25px h-25px cursor-pointer" />
          <SubmissionFilters.IsSaudi.Label title="Saudi Citizen" />
        </div>
        <div class="flex items-center gap-10px">
          <SubmissionFilters.SpeakEnglish.Input
            id="speak_english"
            type="checkbox"
            class="w-25px h-25px cursor-pointer"
          />
          <SubmissionFilters.SpeakEnglish.Label title="Speak English" />
        </div>
        <div class="flex items-center gap-10px">
          <SubmissionFilters.Commission.Input type="checkbox" class="w-25px h-25px cursor-pointer" />
          <SubmissionFilters.SpeakEnglish.Label title="Museums Commission" />
        </div>
        <div class="flex items-center gap-10px">
          <SubmissionFilters.WorkForMoc.Input type="checkbox" class="w-25px h-25px cursor-pointer" />
          <SubmissionFilters.WorkForMoc.Label title="MOC Staff" />
        </div>
      </div>
      <div class="flex gap-30px items-start">
        <SubmissionFilters.EnglishLevel.Select
          placeholder="English Level"
          options={local.englishLevel?.split("|").map((opt) => opt.trim())}
          class={"form-select "}
          onSelected={onFilterChange}
        />
        <SubmissionFilters.Region.Select
          placeholder="Region/City"
          options={local.regions?.map((region) => region.name).map((opt) => opt.trim())}
          class={"form-select "}
          // class={dropdownStyle}
          onSelected={onFilterChange}
        />
        <SubmissionFilters.WhichCommission.Select
          placeholder="Comission"
          options={local.comissions?.map((comission) => comission.name.trim())}
          class={"form-select "}
          onSelected={onFilterChange}
        />
      </div>
    </form>
  );
}
